@charset "UTF-8";
$primary-color: #003399;
$secondary-color: #a0d28b;
$danger-color: #c54854;

$grey-color: #e9ecef;
$dark-grey-color: #aeb2b6;

$border-color: #ced4da;

:root {
    --surface-a: #ffffff;
    --surface-b: #efefef;
    --surface-c: $grey-color;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #212529;
    --text-color-secondary: #6c757d;
    --primary-color: $primary-color;
    --primary-color-text: #ffffff;
    --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0: #ffffff;
    --surface-50: #f9fafb;
    --surface-100: #f8f9fa;
    --surface-200: $grey-color;
    --surface-300: #dee2e6;
    --surface-400: $border-color;
    --surface-500: #adb5bd;
    --surface-600: #6c757d;
    --surface-700: #495057;
    --surface-800: #343a40;
    --surface-900: #212529;
    --content-padding: 1.25rem;
    --inline-spacing: 0.5rem;
}

.p-error,
.p-menu .p-error .p-menuitem-link .p-menuitem-text,
.p-menu .p-error .p-menuitem-link .p-menuitem-icon {
    color: $danger-color;
}

.p-text-secondary {
    color: $dark-grey-color;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: $border-color;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    border-color: $primary-color;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    background: $primary-color;
    color: white;
}

p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
    border-color: $danger-color;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    background: $grey-color;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background: $primary-color;
    color: white;
}

p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
    border-color: $danger-color;
}

.p-datepicker {
    border: 1px solid $border-color;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    border-color: $primary-color;
}

.p-datepicker table td>span.p-highlight {
    background: $primary-color;
    color: white;
}

.p-datepicker table td.p-datepicker-today>span {
    background: $border-color;
}

.p-datepicker table td.p-datepicker-today>span.p-highlight,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: $primary-color;
    color: white;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: $grey-color;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid $border-color;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primary-color;
    background: $primary-color;
    color: white;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $border-color;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $primary-color;
}

p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
    border-color: $danger-color;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: $primary-color;
    color: white;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: $border-color;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    border-color: $primary-color;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    background: $primary-color;
    color: white;
}

p-chips.ng-dirty.ng-invalid>.p-chips>.p-inputtext {
    border-color: $danger-color;
}

.p-dropdown {
    border: 1px solid $border-color;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: $border-color;
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: $primary-color;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: $primary-color;
    color: white;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: $grey-color;
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
    border-color: $danger-color;
}

.p-inputgroup-addon {
    background: $grey-color;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid $border-color;
}

p-inputmask.ng-dirty.ng-invalid>.p-inputtext,
p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
    border-color: $danger-color;
}

.p-inputswitch .p-inputswitch-slider,
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: $border-color;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $primary-color;
    color: white;
}

p-inputswitch.ng-dirty.ng-invalid>.p-inputswitch {
    border-color: $danger-color;
}

.p-inputtext {
    border: 1px solid $border-color;
}

.p-inputtext:enabled:hover {
    border-color: $border-color;
}

.p-inputtext:enabled:focus {
    border-color: $primary-color;
}

.p-inputtext.ng-dirty.ng-invalid {
    border-color: $danger-color;
}

.p-float-label>.ng-invalid.ng-dirty+label {
    color: $danger-color;
}

.p-listbox {
    border: 1px solid $border-color;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background: $primary-color;
    color: white;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background: $grey-color;
}

p-listbox.ng-dirty.ng-invalid>.p-listbox {
    border-color: $danger-color;
}

.p-multiselect {
    border: 1px solid $border-color;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: $border-color;
}

.p-multiselect:not(.p-disabled).p-focus {
    border-color: $primary-color;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: $primary-color;
    color: white;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    background: $grey-color;
}

p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
    border-color: $danger-color;
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid $border-color;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: $border-color;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    border-color: $primary-color;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $primary-color;
    background: $primary-color;
    color: white;
}

p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box {
    border-color: $danger-color;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: $primary-color;
    color: white;
}

p-selectbutton.ng-dirty.ng-invalid>.p-selectbutton>.p-button {
    border-color: $danger-color;
}

p-togglebutton.ng-dirty.ng-invalid>.p-togglebutton.p-button {
    border-color: $danger-color;
}

.p-button {
    background: $primary-color;
    color: white;
    border: 1px solid $primary-color;
}

.p-button.p-button-outlined,
.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:enabled:active {
    color: $primary-color;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: $grey-color;
}

.p-button.p-button-text,
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:enabled:active {
    color: $primary-color;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
    background: $grey-color;
}

.p-button .p-badge {
    color: $primary-color;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button {
    background: $danger-color;
    border: 1px solid $danger-color;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger>.p-button.p-button-text,
.p-splitbutton.p-button-danger>.p-button.p-button-text,
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:active {
    color: $danger-color;
}

.p-button.p-button-link,
.p-button.p-button-link:enabled:active {
    color: $primary-color;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    background: $primary-color;
    color: white;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: $grey-color;
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $primary-color;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: $grey-color;
    color: $primary-color;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: $primary-color;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: $primary-color;
    color: white;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
    box-shadow: inset 0 2px 0 0 $primary-color;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
    box-shadow: inset 0 -2px 0 0 $primary-color;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: $grey-color;
}

.p-datatable .p-column-resizer-helper,
.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
    background: $primary-color;
    color: white;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    background: $primary-color;
    color: white;
}

.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
    background: $grey-color;
}

.p-paginator,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    color: $primary-color;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: $grey-color;
    color: $primary-color;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-pages .p-paginator-page {
    color: $primary-color;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $primary-color;
    border-color: $primary-color;
    color: white;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: $grey-color;
    color: $primary-color;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: $grey-color;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: $grey-color;
}

.p-dialog .p-dialog-header {
    border-bottom: 1px solid $grey-color;
}

.p-dialog .p-dialog-footer {
    border-top: 1px solid $grey-color;
}

.p-overlaypanel .p-overlaypanel-close {
    background: $primary-color;
    color: white;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover,
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-menubar .p-menuitem-link:not(.p-disabled):hover,
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: $grey-color;
}

@media screen and (max-width: 960px) {
    .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
        background: $grey-color;
    }
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover,
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: $grey-color;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover,
.p-slidemenu .p-slidemenu.p-slidemenu-active>.p-slidemenu-link {
    background: $grey-color;
}

.p-badge {
    background: $primary-color;
    color: white;

    &.p-badge-danger {
        background-color: $danger-color;
    }
}


.p-progressbar {
    background: $grey-color;

    .p-progressbar-value {
        background: $primary-color;
        color: white;
    }
}


.p-skeleton {
    background-color: $grey-color;
}

.p-tag {
    background: $primary-color;
    color: white;

    &.p-tag-danger {
        background-color: $danger-color;
    }
}
