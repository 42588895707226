body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f2f5fa;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Istok Web', sans-serif;
}

.w-100 {
    width: 100%;
}

.pos-rel {
    position: relative;
}

.no-pointer-ev {
    pointer-events: none;
}

p-card.h-100 .p-card,
p-card.h-100 .p-card .p-card-body,
p-card.h-100 .p-card .p-card-content {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.card {
    background: var(--surface-e);
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    margin-bottom: 2rem;
}

/* Responsive Table */
.responsive-table .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

@media screen and (max-width: 40rem) {
    .p-datatable {
        &.responsive-table {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr>td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                }
            }
        }
    }
}

/* Simple List */
.simplelist-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none;

    .simplelist-list {
        background: #ffffff;
        color: #212529;
        padding: 0.5rem 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        list-style: none;

        .simplelist-item {
            cursor: pointer;
            padding: 1.7rem 1.5rem;
            margin: 0;
            border: 0 none;
            color: #212529;
            background: transparent;
            border-bottom: 1px solid #dee2e6;

            h5 {
                margin: .5rem 0;
                font-size: 1.25rem;
                font-weight: 600;
                line-height: 1.2;
            }

            span {
                font-size: 14px;
            }
        }
    }
}

/* sets dialog content to visible to show calendar */
p-dynamicdialog .p-dialog-content {
    overflow: visible !important;
}

.p-dialog .p-dialog-content {
    background-color: #eeeeee !important;
}
